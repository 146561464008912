import { useContext, useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthContext } from "../../AuthContext";
import { getLoginRouteByPlatform } from "shared/helpers/loginPageRoute.helper";

const ProtectedRoute = () => {
  const { platform, tokenVerified, verifyToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = async () => {
    if (!tokenVerified) {
      const verified = await verifyToken();
      if(verified) {
        setIsLoggedIn(true);
        return;
      }
      setIsLoggedIn(false);
      const loginRoute = getLoginRouteByPlatform(platform);
      return navigate(loginRoute);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken()
  }, [isLoggedIn]);
  return isLoggedIn ? <Outlet /> : null;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
