// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import brand from "assets/images/logo-mini.png";
import { useSoftUIController } from "context";
import { Outlet } from "react-router-dom";
import routes from "routes";

function RootLayout() {
    const [controller] = useSoftUIController();
    const { layout, sidenavColor } = controller;

    return (
        <>
            {layout === "dashboard" && (
                <>
                <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName="eShop Portal 2"
                    routes={routes}
                />
                {/*<Configurator/>*/}
                {/*{configsButton}*/}
                </>
            )}
            {layout === "vr" && <Configurator />}
            <Outlet />
        </>
    )
}

export default RootLayout