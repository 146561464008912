import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'AuthContext';

const GetQuote = () => {
    const { platform } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        const redirectUrl = `/quote?brand=${platform}&agent=true`
        navigate(redirectUrl);
    }, []);
}

GetQuote.propTypes = {};
GetQuote.defaultProps = {};

export default GetQuote;
