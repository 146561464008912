import { useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from '../../../shared/const/api-backend';
import { UserDataContext } from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useCancelPolicy = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();


  const cancelPolicy = async (policy, refundAmount, refundRequestDate) => {
    const policyId = policy.policy.policyNumber;
    const catalog = policy.product.iac;
    setLoading(true);
    let result;

    try {
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${catalog}/policies/${policyId}`;
      const body = {
        refundAmount: +refundAmount,
        refundRequestDate,
      }
      await API.post(apiUrl, body);
      showNotification("Policy cancelled successfully", "success");
      result = true;
    } catch (error) {
      showNotification("Error cancelling policy", "error");
      reportError(error);
    } finally {
      setLoading(false);
      return result;
    }
  };

  return { cancelPolicy };
};

export default useCancelPolicy;
