import React from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import SoftBox from 'components/SoftBox';
import {normalizeUrl} from "../../../../shared/helpers/url.helper";

const QuoteCard = ({ item }) => {
    const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

    return (
        <SoftBox mb={3}>
            <Card
                className="card-shortcut"
                component={Link}
                to={`/quote?brand=${item.brand}&agent=true`}
            >
                <div className="image-container">
                    <img className="company-logo" src={normalizeUrl(apiStrapiUrl, item.logo)} alt={item.brand}/>
                </div>
                <div className="card-content">
                    <SoftBox
                        variant="gradient"
                        bgColor="white"
                        color="black"
                        width="2rem"
                        height="2rem"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        sx={{marginRight: "0.5rem"}}
                    >
                        <Icon fontSize="default" color="inherit">
                            addBox
                        </Icon>
                    </SoftBox>
                    <span className="brand-name"> {item.brand} </span>
                </div>
            </Card>
        </SoftBox>
    );
}

QuoteCard.propTypes = {
    item: PropTypes.shape({
        brand: PropTypes.string.isRequired,
        logo: PropTypes.string.isRequired
    }).isRequired
};


export default QuoteCard;
