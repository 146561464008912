import React, { useContext } from 'react';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import "./quote.scss";
import { UserDataContext } from "../../../shared/context/userDataContext";

const Quote = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { dataCenter } = useContext(UserDataContext);
    const brand = urlParams.get('brand');
    const quoteId = urlParams.get('quoteId');
    const iframeUrl = `${dataCenter.widgetUrl}?brand=${brand}&agent=true${quoteId ? `&quoteId=${quoteId}` : ''}`;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container style={{ marginTop: '0' }} spacing={3}>
                {dataCenter.widgetUrl && <iframe src={iframeUrl}></iframe>}
            </Grid>
        </DashboardLayout>
    )
};

Quote.propTypes = {};

Quote.defaultProps = {};

export default Quote;
