import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const AuthContext = createContext({});
const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

export const AuthProvider = ({ children }) => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState(
    localStorage.getItem("platform") ? localStorage.getItem("platform") : "puzzle"
  );

  const verifyToken = async () => {
    if(tokenVerified) {
      setLoading(false);
      return true;
    };

    setLoading(true);
    try {
      const response = await fetch(`${apiStrapiUrl}verify-token`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setTokenVerified(true);
      } else {
        setTokenVerified(false);
        setLoading(false);
        return false;
      }
    } catch (error) {
      setTokenVerified(false);
      setLoading(false);
      return false;
    }

    setLoading(false);
    return true;
  }

  const handleRemoveToken = async () => {
    try {
      const response = await fetch(`${apiStrapiUrl}remove-token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        setTokenVerified(false);
      } else {
        console.error('Failed to remove token');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleSetTokenVerified = async (tokenVerified, platform) => {
    setTokenVerified(tokenVerified);
    await verifyToken()
    if (platform) {
      localStorage.setItem("platform", platform);
      setPlatform(platform);
    }
  };

  const value = {
    tokenVerified,
    platform,
    handleSetTokenVerified,
    handleRemoveToken,
    verifyToken
  };

  if(loading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
