/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar } from "context";

import { NotificationContext } from "../../../shared/context/notificationContext";
import SoftSelect from "components/SoftSelect";
import { UserDataContext } from "shared/context/userDataContext";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../AuthContext";
import { getLoginRouteByPlatform } from "shared/helpers/loginPageRoute.helper";

function DashboardNavbar({ absolute, light, isMini }) {
  const { t } = useTranslation();
  const { dataCenter, userData, saveDataCenter } = useContext(UserDataContext);
  const { platform, handleRemoveToken } = useContext(AuthContext);
  const [navbarType, setNavbarType] = useState();
  const [defaultValue, setDefaultValue] = useState([]);
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const isDetailsPage = useLocation().pathname.includes("-details");
  const { showNotification } = useContext(NotificationContext);
  const options =
    userData && userData.dataCenters
      ? userData.dataCenters.map((center) => ({
          value: center.code,
          label: center.code,
        }))
      : [];

  useEffect(() => {
    if (dataCenter && dataCenter.code && !defaultValue.value) {
      setDefaultValue({ value: dataCenter.code, label: dataCenter.code });
    }

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar, dataCenter]);

  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = async () => {
    await handleRemoveToken().then(() => {
      localStorage.removeItem("platform");
      const loginRoute = getLoginRouteByPlatform(platform);
      navigate(loginRoute);
      showNotification("NOTIFICATION.success.logout", "success");
    })
  };

  const handleSelectChange = (selectedOption) => {
    const selectedDataCenter = userData.dataCenters.find(
      (center) => center.code === selectedOption.value
    );
    saveDataCenter(selectedDataCenter);
    setDefaultValue({ value: selectedOption.value, label: selectedOption.value });
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftTypography pr={1} sx={{ fontSize: "1rem" }}>
              {t("HOME.MENU.datacenter")}
            </SoftTypography>
            <SoftBox pr={1}>
              <SoftSelect
                placeholder="Data center..."
                options={options}
                value={defaultValue}
                isDisabled={isDetailsPage}
                onChange={handleSelectChange}
                className="dataCenterSelect"
              />
            </SoftBox>
            <SoftBox color={light ? "white" : "inherit"}>
              {/*<IconButton
                            {/*    size="small"
                            {/*    color="inherit"
                            {/*    sx={navbarMobileMenu}
                            {/*    onClick={handleMiniSidenav}
                            {/*>
                            {/*    <Icon className={light ? "text-white" : "text-dark"}>
                            {/*        {miniSidenav ? "menu_open" : "menu"}
                            {/*    </Icon>
                            {/*</IconButton>
                            {/*<IconButton*/}
              {/*    size="small"*/}
              {/*    color="inherit"*/}
              {/*    sx={navbarIconButton}*/}
              {/*    onClick={handleConfiguratorOpen}*/}
              {/*>*/}
              {/*    <Icon>settings</Icon>*/}
              {/*</IconButton>*/}
              {/*<IconButton*/}
              {/*    size="small"*/}
              {/*    color="inherit"*/}
              {/*    sx={navbarIconButton}*/}
              {/*    aria-controls="notification-menu"*/}
              {/*    aria-haspopup="true"*/}
              {/*    variant="contained"*/}
              {/*    onClick={handleOpenMenu}*/}
              {/*>*/}
              {/*    <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>*/}
              {/*</IconButton>*/}
              <IconButton onClick={handleLogout} sx={navbarIconButton} size="small">
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  logout_icon
                </Icon>

                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  Logout
                </SoftTypography>
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
