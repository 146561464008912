import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "AuthContext";
import { LoaderContext } from "shared/context/loaderContext";
import { NotificationContext } from "shared/context/notificationContext";
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from "shared/services/raygunService";
import PropTypes from "prop-types";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

function Redirect({ provider }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { platform, handleSetTokenVerified } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { fetchDataCenterFromApi } = useContext(UserDataContext);

  async function fetchData() {
    try {
      const callbackUrl = `${apiStrapiUrl}auth/${provider}/callback${location.search}`;
      const response = await fetch(callbackUrl, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const jsonResponse = await response.json();
      await handleSetTokenVerified(jsonResponse.tokenVerified);
      showNotification("NOTIFICATION.success.login", "success");
      fetchDataCenterFromApi();
      navigate("/dashboard");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification("COMMON.error.fetchingData", "error");
      reportError(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [location.search, navigate, handleSetTokenVerified, showNotification, setLoading, platform, provider]);

  return null;
}

Redirect.propTypes = {
  provider: PropTypes.oneOf(["microsoft", "okta"]),
};

export default Redirect;
