import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { reportError } from "shared/services/raygunService";

export const UserDataContext = React.createContext();

export const UserDataProvider = ({ children }) => {
  const { tokenVerified, handleRemoveToken } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [dataCenter, setDataCenter] = useState([]);
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const fetchDataCenterFromApi = async () => {
    try {
      const selectedDataCenterCode = localStorage.getItem("selectedDataCenter");
      const apiUrl = `${apiStrapiUrl}users/me`;
      const response = await axios.get(apiUrl, {
        withCredentials: true
      });

      const userData = response.data;
      const dataCentersObject = userData.dataCenters;
      setUserData(userData);
      if (selectedDataCenterCode) {
        const selectedDataCenter = dataCentersObject.find(
          (center) => center.code === selectedDataCenterCode
        );
        setDataCenter(selectedDataCenter);
      } else {
        setDataCenter(dataCentersObject?.[0] || []);
      }
    } catch (error) {
      if (error.response?.data?.error?.name === "UnauthorizedError") {
        await handleRemoveToken().then(() => {
          window.location.reload();
        })
      }
      reportError(error);
    }
  };

  useEffect(() => {
    if (dataCenter && dataCenter.length === 0 && tokenVerified) {
      fetchDataCenterFromApi();
    }
  }, [dataCenter]);

  const saveDataCenter = (dataCenter) => {
    setDataCenter(dataCenter);
    localStorage.setItem("selectedDataCenter", dataCenter.code);
  };

  const value = {
    dataCenter,
    userData,
    saveDataCenter,
    fetchDataCenterFromApi,
  };

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
};

UserDataProvider.propTypes = {
  children: PropTypes.node,
};
